import React, { useContext, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { Redirect, Route } from 'react-router-dom';

import { AuthContext } from '@contexts';
import { PAGES } from '@helpers';

const PrivateRoute = ({ children, comp: Component, ...rest }: any) => {
  const { user } = useContext(AuthContext);
  useEffect(() => {
    // @ts-ignore
    if (user?.username) {
      Sentry.setUser({ email: user?.attributes?.email, username: user?.username });
    }
    // @ts-ignore
  }, [user?.username]);

  return <Route {...rest}>{!user ? <Redirect to={PAGES.Login} /> : children}</Route>;
};
export default PrivateRoute;
